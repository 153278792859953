.swipperModal {
  max-width: 70vh;
  padding-top: 20px;

  @media (max-width: 767px) {
    max-width: calc(100vw - 48px);
    padding-top: 60px;
  }

  & img {
    width: 100%;
    height: 100%;
    background: #fff;
  }
}

.previewSlider {
  margin-bottom: 16px;

  & img {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
  }

  & :global(.slick-dots) {
    position: relative;
    display: flex !important;
    bottom: -8px;
    & li {
      width: 100px;
      height: 100px;
    }
  }

  & :global(.slick-next) {
    right: 45px;

    &:before {
      font-size: 45px;
    }
  }

  & :global(.slick-prev) {
    left: 20px;
    z-index: 9;

    &:before {
      font-size: 45px;
    }
  }

  & :global(.slick-arrow) {
    top: 43%;
    filter: drop-shadow(0px 3px 6px #000);
  }
}

.customImage {
  width: 100px;
  height: 100px;
  /* position: absolute; */

  & img {
    object-fit: cover;
  }

  & embed {
    width: 100px;
    height: 100px;
  }

  & .pdfBox {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.fileIconModal {
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
}

.sliderImage {
  position: relative;
  padding-bottom: 100%;

  & img {
    position: absolute;
    object-fit: cover;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  & embed {
    position: absolute;
    object-fit: cover;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  & button {
    position: absolute;
    right: 35px;
    bottom: 29px;
    width: fit-content;
    height: 32px;
    min-height: 32px;
    padding: 0 22px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.2px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;

    @media (max-width: 767px) {
      right: 16px;
      bottom: 16px;
    }
  }
}

.previewModal {
  &>div {
    background: rgba(20, 19, 19, 0.75) !important;
    align-items: center !important;
    justify-content: center !important;
    overflow-x: hidden !important;

    &>div {
      padding: 20px !important;
      background-color: transparent !important;
      margin: 0 !important;
    }
  }

  & .modalImage {
    position: relative;
    padding-bottom: 100%;

    & img {
      position: absolute;
      object-fit: cover;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    & embed {
      position: absolute;
      object-fit: cover;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    /* height: 56px;
    @media (max-width: 767px) {
      height: 40px;
    } */
    & .fileName {
      color: #fff;
      text-align: center;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: capitalize;
    }

    & button {
      color: #fff;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: capitalize;
      width: 217px;

      @media (max-width: 767px) {
        width: 160px;
        min-height: 40px;
      }
    }
  }

  & :global(.swiper-button-prev) {
    &:after {
      position: absolute;
      top: 0;
      left: -92px;
      content: '';
      background-size: 40px;
      height: 40px;
      width: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33px' height='33px' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M10.843 13.069L6.232 8.384a.546.546 0 0 1 0-.768l4.61-4.685a.55.55 0 0 0 0-.771a.53.53 0 0 0-.759 0l-4.61 4.684a1.65 1.65 0 0 0 0 2.312l4.61 4.684a.53.53 0 0 0 .76 0a.55.55 0 0 0 0-.771'/%3E%3C/svg%3E");

      @media (max-width: 1023px) {
        background-size: 30px;
        height: 30px;
        width: 30px;
        left: 12px;
        background-color: #00000047;
        border-radius: 100px;
      }
    }
  }

  & :global(.swiper-button-next) {
    &:after {
      position: absolute;
      top: 0;
      right: -92px;
      content: '';
      background-size: 40px;
      height: 40px;
      width: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33px' height='33px' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='m5.157 13.069l4.611-4.685a.546.546 0 0 0 0-.768L5.158 2.93a.55.55 0 0 1 0-.771a.53.53 0 0 1 .759 0l4.61 4.684a1.65 1.65 0 0 1 0 2.312l-4.61 4.684a.53.53 0 0 1-.76 0a.55.55 0 0 1 0-.771'/%3E%3C/svg%3E");

      @media (max-width: 1023px) {
        background-size: 30px;
        height: 30px;
        width: 30px;
        right: 12px;
        background-color: #00000047;
        border-radius: 100px;
      }
    }
  }

  & :global(.swiper) {
    overflow: visible !important;
  }

  & :global(.swiper-slide) {
    visibility: hidden;
  }

  & :global(.swiper-slide-visible) {
    visibility: visible;
  }
}

.thumbsSwiper {
  max-width: 612px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.orderPanelTitle {
  /* Font */
  color: #000;
  font-family: 'Lato';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  /* Layout */
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.previewSlider {
  width: 100%;
  margin-bottom: 20px;
}

.sliderImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customImage {
  display: inline-block;
  margin: 5px;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modalImage {
  position: relative;
}

.modalImage img,
.modalImage embed {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.navigation button {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  padding: 0;
  color: transparent;
  border: none;
  background: #FFF;
  cursor: pointer;
  & svg{
    width: 100%;
    height: 100%;
  }
}

.navigation button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  z-index: 1000;
  /* Ensure modal is on top */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0;
  max-width: 70%;
  max-height: 80%;
  width: 100%;
  position: relative;
  @media (max-width: 1023px) {
    max-width: 90%;
  }
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 24px;
  z-index: 99;
  color: #333;
}

.modalClose:hover {
  color: #000;
}

.modalImage {
  text-align: center;
  height: 100%;
  width: 100%;
}

.modalImage img,
.modalImage embed {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.blurImg img{
  filter: blur(25px);
}

.blurImg1 img {
  filter: blur(10px);
}